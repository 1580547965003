<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Representative List
        <v-spacer></v-spacer>
        <v-btn
          elevation="2"
          class="mx-3"
          color="primary"
          @click="
            rep_dialog = true;
            getAttendeeList();
          "
          >Add New</v-btn
        >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.data"
        :server-items-length="items.total"
        :options.sync="pagination"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item.action="{ item }">
          <v-btn color="grey darken-2" icon dark @click="confirmDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!-- Select Representative-->
    <v-dialog v-model="rep_dialog" max-width="320">
      <v-card>
        <v-card-title>Select Representative</v-card-title>
        <v-card-text class="pb-0">
          <v-autocomplete
            v-model="rep_data.selected_rep"
            :items="representative_list"
            :error-messages="form_error.rep_id"
            label="Select Representative"
            item-text="full_name"
            item-value="attendee_id"
            outlined
            dense
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-4"
            text
            @click="rep_dialog = false; form_error = {}"
            >Close</v-btn
          >
          <v-btn color="secondary" text @click="addRepresentative(vendor)"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5">
          Are you sure to remove
          <b>{{ vendor.attendee.full_name }}</b> from representative?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-4" text @click="deleteItem(vendor)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { parseParams, getAlphabets } from "../../plugins/helper";
import { mapMutations } from "vuex";
export default {
  name: "RepresentativeList",
  data() {
    return {
      loading: false,
      dialog: false,
      rep_dialog:false,
      representative_list: [],
      rep_data: { selected_rep: null },
      form_error: {},
      vendor: {
        vendor: { name: null },
        attendee: { full_name: null },
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      headers: [
        {
          text: "Assigned Attendee",
          value: "attendee.full_name",
          sortable: false,
        },
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 10,
        sortBy: ["created_at"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search() {
      this.getItems();
    },
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios
        .get("representative/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.dialog = true;
      this.vendor = item;
    },
    getAttendeeList() {
      let _self = this;
      this.loading = "secondary";
      this.$axios.get("attendee/list?pagination=false")
        .then((response) => {
          _self.representative_list = response.data.data;
          this.loading = false;
        }).catch(function () {
          this.loading = false;
        });
    },
    deleteItem(item) {
      console.log(item);
      const index = this.items.data.indexOf(item);
      let _self = this;
      _self.loading = "secondary";
      this.$axios
        .post("representative/delete", {
          rep_id: item.rep_id,
        }).then((res) => {
          if (res.status) {
            _self.items.data.splice(index, 1);
            _self.dialog = false;
          }
          _self.loading = false;
        }).catch(function () {
          _self.loading = false;
        });
    },
    addRepresentative() {
      let _self = this;
      _self.loading = "secondary";
      this.$axios.post("assign-representative", {
          rep_id: _self.rep_data.selected_rep,
        }).then((res) => {
          if (res.data.status) {
            _self.getItems()
            _self.rep_data.selected_rep = null
            _self.rep_dialog = false;
          } else {
            let errors = res.data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        }).catch(function () {
          _self.loading = false;
        });
        _self.form_error = {};
    },
  },
};
</script>