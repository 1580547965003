const state = {
  alert : {
    show : false,
    position : 'bottom',
    variant : 'success',
    message : '',
    dismissCountDown : 0,
  },
  isBoothPreview : false
}
const  actions = {

}
const getters = {

}
const mutations = {
  setAlert(state,data){
    state.alert.show = data.show
    state.alert.position = data.position
    state.alert.variant = data.variant
    state.alert.message = data.message
    state.alert.dismissCountDown = data.dismissCountDown
  },
  setUtilData(state,data){
    state[data.key] = data.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}