<template>
  <span>
    <basic-booth-preview v-if="user_data.sponsor_type == 3" />
    <gold-booth-preview v-if="user_data.sponsor_type == 4" />
    <diamond-booth-preview v-if="user_data.sponsor_type == 6" />
  </span>
</template>
<script>
import BasicBoothPreview from "@/components/booth/BasicBoothPreview";
import GoldBoothPreview from "@/components/booth/GoldBoothPreview";
import DiamondBoothPreview from "@/components/booth/DiamondBoothPreview";
import { mapMutations, mapState } from "vuex";
export default {
  name: "MainImage",
  components: {
    BasicBoothPreview,
    GoldBoothPreview,
    DiamondBoothPreview
  },
  data(){
    return {
      user_data : {
        sponsor_type : ''
      }
    }
  },
  computed : {
    ...mapState("utils", ["isBoothPreview"]),
  },
  methods : {
    ...mapMutations("utils", ["setUtilData"]),
  },
  mounted(){
    this.user_data = JSON.parse(localStorage.getItem("vendor_data"));
  }
}
</script>