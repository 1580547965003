<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Resource List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.data"
        :server-items-length="items.total"
        :options.sync="pagination"
        :loading="loading"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:item.file="{ item }">
          <v-btn
            v-if="item.file_type == 'IMAGE'"
            color="grey darken-2 px-2"
            small
            dark
            @click="showPreview(item)"
          >
            <v-icon class="pr-2">mdi-image-area</v-icon> View
          </v-btn>
          <v-btn
            v-if="item.file_type == 'VIDEO'"
            color="grey darken-2 px-2"
            small
            dark
            @click="showPreview(item)"
          >
            <v-icon class="pr-2">mdi-play</v-icon> Play
          </v-btn>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            color="grey darken-2"
            icon
            dark
            @click="viewResource(item.file)"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn
            color="grey darken-2"
            icon
            dark
            @click="editResource(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="grey darken-2" icon dark @click="confirmDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      color="primary"
      fixed
      fab
      bottom
      right
      @click="show_add_resource_modal = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5"> Are you sure delete? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-4" text @click="deleteItem(resource_data)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Video play -->
    <v-dialog v-model="preview_data" max-width="400" v-if="show_preview">
      <v-card>
        <v-card-text class="pt-2 rounded-lg px-0">
          <video
            v-if="preview_data.file_type == 'VIDEO'"
            :src="preview_data.file"
            width="100%"
            controls
          ></video>
          <v-img
            v-if="preview_data.file_type == 'IMAGE'"
            :src="preview_data.file"
            contain
            max-height="300px"
          ></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_preview = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add Resource Modal -->
    <v-dialog v-model="show_add_resource_modal" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">Add Resource</v-card-title>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="form_data.title"
                :error-messages="form_error.title"
                dense
                label="Title"
                outlined
                :hide-details="form_error.title == null"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                rows="3"
                v-model="form_data.description"
                :error-messages="form_error.description"
                dense
                label="Description"
                outlined
                :hide-details="form_error.description == null"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" md="11" sm="11">
              <v-file-input
                v-model="form_data.file"
                :error-messages="form_error.file"
                accept="image/*, video/*,document/*,.pdf"
                placeholder="Select File"
                prepend-icon="mdi-file"
                outlined
                dense
                persistent-hint
                v-if="custom_file_mode"
              ></v-file-input>
              <v-text-field
                v-model="form_data.link"
                :error-messages="form_error.link"
                dense
                label="Link"
                prepend-icon="mdi-link"
                outlined
                placeholder="https://"
                v-else
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="custom_file_mode = !custom_file_mode"
                    v-bind="attrs"
                    v-on="on"
                    left
                    class="mt-3"
                  >
                    <v-icon v-if="!custom_file_mode"
                      >mdi-file-upload-outline</v-icon
                    >
                    <v-icon v-else>mdi-link</v-icon>
                  </v-btn>
                </template>
                <span
                  >Switch to
                  {{ !custom_file_mode ? "File upload" : "Link" }}</span
                >
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            @click="addObject"
            :loading="loading"
            :disabled="loading"
            >save</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="
              show_add_resource_modal = false;
              form_error = {};
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Edit Resource Modal -->
    <v-dialog v-model="show_edit_resource_modal" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">Edit Resource</v-card-title>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="edit_form_data.title"
                :error-messages="edit_form_error.title"
                dense
                label="Title"
                outlined
                :hide-details="edit_form_error.title == null"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                rows="3"
                v-model="edit_form_data.description"
                :error-messages="edit_form_error.description"
                dense
                label="Description"
                outlined
                :hide-details="edit_form_error.description == null"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            @click="editObject"
            :loading="loading"
            :disabled="loading"
            >save</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="
              show_edit_resource_modal = false;
            edit_form_error = {};
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { parseParams, getAlphabets } from "@/plugins/helper";
import { mapMutations } from "vuex";
export default {
  name: "ResourceList",
  data() {
    return {
      show_add_resource_modal: false,
      form_data: {
        vendor_id: null,
        resource_type_id: 3,
      },
      form_error: {},
      vendor_id: null,
      preview_data: null,
      show_preview: false,
      loading: false,
      dialog: false,
      resource_data: {},
      search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      headers: [
        { text: "Title", value: "title" },
        { text: "Description", value: "description" },
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 10,
        sortBy: ["resource_id"],
        sortDesc : true,
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
      custom_file_mode: false,
      sizename : ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      usage_satistics : {
        remaining : 0
      },
      show_edit_resource_modal : false,
      edit_form_data: {},
      edit_form_error: {},
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "title";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search() {
      this.getItems();
    },
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
    show_add_resource_modal : {
      handler : function(val){
        if(!val){
          this.form_data = {
            vendor_id: null,
            resource_type_id: 3
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    showPreview(item) {
      this.preview_data = item;
      this.show_preview = true;
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios
        .get(
          "/resource/list?" +
            url +
            "&vendor_id=" +
            _self.$route.params.vendor_id
        )
        .then((response) => {
          _self.items = response.data.data;
          _self.usage_satistics = response.data.usage
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    viewResource(href) {
      if (href) {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href,
        }).click();
      }
    },
    confirmDelete(item) {
      this.dialog = true;
      this.resource_data = item;
    },
    deleteItem(item) {
      let _self = this;
      _self.loading = "secondary";
      this.$axios
        .post("/resource/delete", {
          resource_id: item.resource_id,
        })
        .then((res) => {
          if (res.status) {
            _self.getItems();
            _self.dialog = false;
            _self.setAlert({
              show: true,
              variant: "success",
              message: res.data.message,
              dismissCountDown: 5000,
            });
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
    addObject() {
      let _self = this;
      this.loading = "secondary";
      let serverData = JSON.parse(JSON.stringify(this._data.form_data));
      let formData = new FormData();
      for (let key in serverData) {
        formData.append(key, serverData[key]);
      }
      if (this.form_data.file) {
        formData.append("file", this.form_data.file);
      }
      formData.append("is_file", this.custom_file_mode);
      this.$axios
        .post(`resource/add`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.show_add_resource_modal = false;
            _self.getItems();
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    niceBytes(x) {
      let l = 0,
        n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + this.sizename[l];
    },
    editResource(item) {
      this.show_edit_resource_modal = true
      let json = JSON.parse(JSON.stringify(item))
      this.edit_form_data = json
    },
    editObject() {
      let _self = this;
      this.loading = "secondary";
      let serverData = JSON.parse(JSON.stringify(this._data.edit_form_data));
      _self.edit_form_error = {};
      this.$axios
        .post(`resource/edit`, serverData)
        .then((response) => {
          let response_data = response.data
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.show_edit_resource_modal = false;
            _self.getItems();
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.edit_form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted() {
    console.log(this.$route.params.vendor_id);
  },
};
</script>